'use client';

import { Tab, Tabs } from '@appkit4/react-components';
import { useTranslations } from 'next-intl';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import React, { useCallback, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

const ChatHeaderTabs = () => {
  const { workflowId } = useParams();
  const router = useRouter();
  const searchParams = useSearchParams();
  const t = useTranslations();
  const pathname = usePathname();

  const navItems = [
    {
      label: t('home'),
      value: 'home',
      onClick: () => {
        router.push('/home');
      },
      disabled: false,
    },
    {
      label: t('chat'),
      value: 'chat',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/publishedWorkflow/${workflowId}/chat?session=${searchParams.get('session')}`;

          router.push(sanitizeHtml(newRoute));
        } else {
          const newRoute = `/home/publishedWorkflow/${workflowId}/chat`;
          router.push(sanitizeHtml(newRoute));
        }
      },
    },
    {
      label: t('resources'),
      value: 'resources',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/publishedWorkflow/${workflowId}/resources?session=${searchParams.get('session')}`;
          router.push(sanitizeHtml(newRoute));
        }
      },
      disabled: true,
    },
    {
      label: t('settings'),
      value: 'settings',
      onClick: () => {
        if (searchParams.has('session')) {
          const newRoute = `/home/publishedWorkflow/${workflowId}/settings?session=${searchParams.get('session')}`;
          router.push(sanitizeHtml(newRoute));
        }
      },
      disabled: true,
    },
  ];

  const initialActiveIndex = useCallback(() => {
    if (pathname.includes('chat')) {
      return 1;
    }
    if (pathname.includes('resources')) {
      return 2;
    }
    if (pathname.includes('settings')) {
      return 3;
    }
    return 0;
  }, [pathname]);

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  useEffect(() => {
    setActiveIndex(initialActiveIndex);
  }, [pathname]);

  const onTabChange = (i: number) => {
    if (i === activeIndex) return;
    setActiveIndex(i);
    navItems[i]?.onClick();
  };

  return (
    <div suppressHydrationWarning={true}>
      <Tabs
        id="chatTabsContainer"
        type="underline"
        activeIndex={activeIndex}
        onTabChange={onTabChange}
      >
        {navItems.map((item) => (
          <Tab
            key={item.label}
            label={item.label}
            value={item.value}
            disabled={item.disabled}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default ChatHeaderTabs;
