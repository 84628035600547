'use client';

import './shareModal.styles.css';

import { Button, TextArea } from '@appkit4/react-components';
import { CalendarPicker } from '@appkit4/react-components/datepicker';
import { Modal } from '@appkit4/react-components/modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import useToast from '@/hooks/useToast';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { commaSeparatedEmailsSchema } from '@/utils/emailValidator';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  modalAction: (
    values: FormInputs,
    onSuccess: () => void,
    onFail: () => void,
  ) => void;
  isLoading: boolean;
};

type FormInputs = {
  emails: string;
  dates: Array<Date>;
};
export const ShareModal = ({
  setVisible,
  visible,
  isLoading,
  modalAction,
}: Props) => {
  const t = useTranslations();

  const path = usePathname();
  const toast = useToast();

  const formSchema = z
    .object({
      emails: commaSeparatedEmailsSchema,
      dates: z.array(z.date()).length(2),
    })
    .required();
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      emails: '',
      dates: [],
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (formValues: FormInputs) => {
    console.log(formValues, ' formValues');
    modalAction(
      formValues,
      () => {
        reset();
        setVisible(false);
      },
      () => {
        reset();
        setVisible(false);
      },
    );
  };
  const onCopyLink = () => {
    copyToClipboard(`${window.location.origin}${path}`);

    toast({
      message: t('linkCopied'),
      status: 'success',
    });
  };

  return (
    <Modal
      id="shareModal"
      onClose={reset}
      visible={visible}
      title={t('invTeamMem')}
      ariaLabel={t('invTeamMem')}
      onCancel={() => setVisible(false)}
      modalStyle={{ width: '39rem', overflow: 'visible' }}
      footerStyle={{ paddingTop: '8px', marginTop: '-8px' }}
      bodyStyle={{ paddingBlock: '20px', overflow: 'visible' }}
      footer={
        <>
          <Button onClick={onCopyLink} kind="text" icon="icon-link-outline">
            {t('copyLink')}
          </Button>
          <div className="flex gap-2">
            <Button
              disabled={isLoading}
              onClick={() => setVisible(false)}
              kind="secondary"
            >
              {t('cancel')}
            </Button>
            <Button
              loading={isLoading}
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {t('sendInv')}
            </Button>
          </div>
        </>
      }
    >
      <div className="flex w-full gap-6 overflow-visible">
        <Controller
          rules={{ required: true }}
          control={control}
          name="emails"
          render={({ field }) => (
            <TextArea
              title="Add emails (comma separated)"
              hideTitleOnInput={true}
              autosize={{ maxRows: 5, minRows: 1 }}
              style={{ flexGrow: 1 }}
              {...field}
            />
          )}
        />
        <Controller
          rules={{ required: true }}
          control={control}
          name="dates"
          render={({ field }) => (
            <CalendarPicker
              fieldWidth={256}
              minDate={new Date()}
              {...field}
              datepickerAlwaysDown={false}
              dropdownRenderMode="default"
              selectRange={true}
              placeholder="mm/dd/yyyy to mm/dd/yyyy"
              mode="showDoubleCalendar"
              locale="en"
              fieldTitle="Date range"
            />
          )}
        />
      </div>
    </Modal>
  );
};
