import { Tab, Tabs } from '@appkit4/react-components';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect } from 'react';

export const MainHeaderTabs = () => {
  const router = useRouter();
  const path = usePathname();
  const t = useTranslations('BuildYourBot');
  const [activeIndex, setActiveIndex] = React.useState(0);

  const navItems = [
    {
      label: t('home'),
      value: 'home',
      onClick: () => {
        router.push('/home');
      },
      disabled: false,
    },
    {
      label: t('BYB'),
      value: 'byob',
      onClick: () => {
        router.push('/home/byob/create-workspace');
      },
    },
    {
      label: t('workspaces'),
      value: 'workspaces',
      onClick: () => {
        router.push('/home/workspaces');
      },
    },
    {
      label: t('marketplace'),
      value: 'marketplace',
      onClick: () => {
        router.push('/home/marketplace');
      },
    },
    {
      label: t('helpAndSupport'),
      value: 'help&support',
      onClick: () => {
        router.push('/home/help&support');
      },
    },
    {
      label: t('settings'),
      value: 'settings',
      onClick: () => {
        router.push('/home/settings');
      },
    },
  ];
  useEffect(() => {
    if (path.endsWith('/home')) setActiveIndex(0);
    if (path.includes('/byob')) setActiveIndex(1);
    if (path.includes('/workspaces')) setActiveIndex(2);
    if (path.includes('/marketplace')) setActiveIndex(3);
    if (path.includes('/help&support')) setActiveIndex(4);
    if (path.includes('/settings')) setActiveIndex(5);
  }, [path]);

  const onTabChange = (i: number) => {
    setActiveIndex(i);
    navItems[i]?.onClick();
  };

  return (
    <Tabs
      id="tabsContainer"
      type="underline"
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    >
      {navItems.map((item) => (
        <Tab
          key={item.label}
          label={item.label}
          value={item.value}
          disabled={item.disabled}
        />
      ))}
    </Tabs>
  );
};
